import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Connect } from "@vkontakte/superappkit";
import { Box, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import style from './Header.module.css';
// eslint-disable-next-line boundaries/element-types
import { clearStorage } from "../../../entities/auth/tokenResource/redux/tokenResource";
import { LANGUAGES } from '../../../i18n';
import useAuth from '../../lib/hooks/useAuth';

const appVersion = process.env.REACT_APP_VERSION || '';

function Header(props) {
  const { handleDrawer } = props;
  const { user, companyAccount, isCompanyAdmin } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [adminLogin, setAdminLogin] = useState();

  useEffect(() => {
    const atoken = window.sessionStorage.getItem('atoken');
    const aLogin = window.sessionStorage.getItem('admin_login');
    if (atoken) {
      setAdminLogin(aLogin || 'admin');
    } else {
      setAdminLogin(undefined);
    }
  }, []);
  const { t, i18n } = useTranslation('translation');

  const nextLang = useMemo(() => {
    const lngs = Object.keys(LANGUAGES);
    // В массив i18n.languages попадают только значения из доступных
    // В i18n.language может быть непредвиденное значение языка браузера с суффиксами
    const currentLang = i18n?.languages ? i18n?.languages[0] : i18n?.language;
    let nextLngIndex = lngs.findIndex((l) => currentLang === l) + 1;
    nextLngIndex = nextLngIndex > lngs.length - 1 ? 0 : nextLngIndex;
    return lngs[nextLngIndex];
  }, [i18n?.language]);

  const toggleLanguage = useCallback(() => {
    if (i18n?.language) {
      i18n.changeLanguage(nextLang);
    }
  }, [i18n?.language]);

  const balanceStr = useMemo(() => {
    if (companyAccount?.balance !== undefined) {
      // TODO Использовать интернационализацию здесь:
      return new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(
        companyAccount.balance,
      );
    }
    return '';
  }, [companyAccount?.balance]);

  const logout = () => {
    Connect.logout().finally(() => {
      clearStorage();
      // eslint-disable-next-line no-undef
      window.location.href = '/';
    });
  };

  const goToProfile = () => {
    navigate('account/info');
  };

  const resetAdminMode = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const atoken = window.sessionStorage.getItem('atoken');
    clearStorage();
    window.sessionStorage.setItem('token', atoken);
    navigate('/impersonize', { state: { from: location } });
  };

  return (
    <AppBar sx={{ zIndex: 1500 }} position="static" color="primary" className={style.header}>
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={handleDrawer}
        >
          <MenuIcon />
        </IconButton>
        <Box className={style.headerMain}>
          <Box className={style.headerText}>
            <Typography variant="h6" component="div" className={style.titleText}>
              {t('title')}
            </Typography>
            <Typography variant="caption" component="div">
              {appVersion}
            </Typography>
          </Box>
        </Box>

        <div className={style.buttons}>
          <Tooltip title={t('switchLanguage', { lng: nextLang })}>
            <Button onClick={toggleLanguage} color="inherit">{LANGUAGES[nextLang].short}</Button>
          </Tooltip>
          <Tooltip title="Быстрый старт">
            <IconButton
              color="inherit"
              href="/quick-start"
            >
              <HelpOutlineIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          {
            isCompanyAdmin && (
              <Typography sx={{
                backgroundColor: (theme) => (companyAccount?.balance < 0 ? theme.palette.error.main : 'inherit'),
                padding: '0 4px',
                borderRadius: '4px',
              }}
              >
                {balanceStr}
              </Typography>
            )
          }
          <Button onClick={goToProfile} color="inherit" startIcon={<PersonIcon />} sx={{ textTransform: 'none' }}>
            {user.login}
            {adminLogin && (
              <>
                <Typography>&nbsp;</Typography>
                <Typography onClick={resetAdminMode} variant="inherit" component="span">
                  (
                  {adminLogin}
                  )
                </Typography>
              </>
            )}
          </Button>
          <Button onClick={logout} color="inherit">Выход</Button>
        </div>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  handleDrawer: PropTypes.func.isRequired,
};

export default Header;
