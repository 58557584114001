const ERROR_CODES = {
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  NOT_FOUND: 'NOT_FOUND',
  FORBIDDEN: 'FORBIDDEN',
  GEOZONE_NOT_DELETE: 'GEOZONE_NOT_DELETE',
  NO_RESPONSE: 'NO_RESPONSE',
  NO_REQUEST: 'NO_REQUEST',
  COMPANY_FOR_LOGISTIC_NOT_FOUND: 'COMPANY_FOR_LOGISTIC_NOT_FOUND',
  CHECK_THE_DATA: 'CHECK_THE_DATA',
  'https://www.jhipster.tech/problem/problem-with-message': 'WRONG_VEHICLES_IN_IMPORTED_FILE',
};
// TODO Синхронизировать коды ошибок с бэком

export default ERROR_CODES;
